
:root {
    --transition-time: 2.3s;
    overflow-x: hidden;
}
#cardSection{
    padding: 20px;
}
.card {
    position: relative;
    height: 400px;
    width: 100%;
    margin: 10px 0;
    transition: ease all var(--transition-time);
    perspective: 1200px;
    border: none;
}
@media (min-width: 1454px) {
    .card {
        height: 500px;
    }
}
.card:hover .cover {
    transform: rotateX(0deg) rotateY(-180deg);
}

.card:hover .cover:before {
    transform: translateZ(30px);
}

.card:hover .cover:after {
    background-color: black;
}

.card:hover h1 {
    transform: translateZ(100px);
}

.card:hover .price {
    transform: translateZ(60px);
}

.card:hover a {
    transform: translateZ(-60px) rotatey(-180deg);
}

.cover {
    position: absolute;
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transition: ease all var(--transition-time);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.cover:before {
    content: '';
    position: absolute;
    border: 5px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    transition: ease all var(--transition-time);
    transform-style: preserve-3d;
    transform: translateZ(0px);
}

.cover:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    transition: ease all 1.3s;

}

.cover.item-a {
    background-image: url("/public/assets/EvaCoreLogo.png");
}

.cover.item-b {
    background-image: url("/public/assets/EvaBrandLogo.png");
}

.cover.item-c {
    background-image: url("/public/assets/Evarenew2.png");}

.h1Card {
    font-weight: 600;
    position: absolute;
    bottom: 55px;
    left: 50px;
    color: white;
    transform-style: preserve-3d;
    transition: ease all var(--transition-time);
    z-index: 3;
    font-size: 3em;
    transform: translateZ(0px);
}

.card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #0b0f08;
    transform-style: preserve-3d;
    transition: ease all var(--transition-time);
    transform: translateZ(-1px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-back a {
    transform-style: preserve-3d;
    transition: ease transform var(--transition-time), ease background 0.5s;
    transform: translateZ(-1px) rotatey(-180deg);
    background: transparent;
    border: 1px solid white;
    font-weight: 200;
    font-size: 1.3em;
    color: white;
    padding: 14px 32px;
    outline: none;
    text-decoration: none;
}

.card-back a:hover {
    background-color: white;
    color: #0b0f08;
}
#card-box {
    margin-top: -20%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    width: 65%;
    height: 300px;
    background-color: #28a745;
}
.productCard{
    margin-top: 15%;
}

@media (max-width: 991px) {
    #card-box {
        width: 100%;
        margin-top: 0;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media (max-width: 500px) {
    #card-box {
        margin-top: -10%;
        flex-direction: column;
        overflow-y: scroll;
    }
}

#card-text {
    padding: 20px;
}

#cardBodyHome {
    padding: 1px!important;
}

#cardTitle {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 5px;
}

#nearLogoExp {
    display: flex;
    justify-content: center;
    align-items: center;
}
.divider-horizontal{
    color: #28a745;
}
@media (min-width: 500px) {
    .divider-horizontal{
        margin-top: 6%;
        width: 1px;
        height: 60%; /* veya istediğiniz bir yükseklik */
        background-color: #EF7215; /* veya istediğiniz renk */
        color: #021E17;
    }
}
.previousTag{
    left: 0;
    width: 50px;
    position: fixed;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: -250px;
}
#previousTagId{
    width: 50px;
    height: 50px;
}
/*EVABRAND*/
.documentSection{
    margin-bottom: 20%;
}
/*EvaRenew*/
#bloomLogo{
    width: 50%;
    margin-top: 10%;
}
.homeIcon{
    color:white ;
}